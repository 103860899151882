import { Link } from 'gatsby'
import React from 'react'

const logo = require('../../assets/images/blue.png')

function NotFoundNav() {
  return (
    <nav className='navbar navbar-expand resized'>
      <Link className='text-center' to='/'>
        <h1 className='navbar-brand mb-0 text-stratosBlue'>
          <img src={logo} style={{ height: 70 }} />
          Stratos
        </h1>
      </Link>
    </nav>
  )
}

export default NotFoundNav
