import { navigate } from '@reach/router'
import { Link } from 'gatsby'
import React from 'react'

const $ = typeof window !== 'undefined' ? require('jquery').noConflict() : null
const logo = require('../../assets/images/white.png')

function HomeNav() {
  if (typeof document !== 'undefined') {
    $(document).ready(function() {
      $('.nav-link').on('click', function() {
        $('.navbar-collapse').collapse('hide')
      })
      $('.navbar').on('show.bs.collapse', function() {
        $('.navbar-default').css('background-color', '#03a9f4')
      })

      $('.navbar').on('hide.bs.collapse', function() {
        $('.navbar-default').css('background-color', 'transparent')
      })
    })
  }

  return (
    <nav className='navbar navbar-default navbar-expand-lg navbar-dark resized navbar-toggleable-md'>
      <Link className='text-center' to='/'>
        <h1 className='navbar-brand mb-0 text-default'>
          <img src={logo} style={{ height: 70, width: 70 }} alt='Stratos Logo' />
          Stratos
        </h1>
      </Link>

      <button
        className='navbar-toggler navbar-toggler-right navbar-yellow'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNav'
        aria-controls='navbarNav'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon'></span>
      </button>
      <div className='collapse navbar-collapse' id='navbarNav'>
        <div className='navbar-nav-scroll d-flex flex-grow-1' />
        <div className='navbar-nav-scroll'>
          <ul className='ml-auto navbar-nav bd-navbar-nav right'>
            <li className='nav-item'>
              <a
                className='nav-link active text-default navSize'
                target='_blank'
                href='https://docs.stratos.trade'
                rel='noreferrer'
              >
                Docs
              </a>
            </li>
            <li className='nav-item'>
              <a className='nav-link active text-default navSize' href='#how-it-works'>
                How It Works
              </a>
            </li>
            <li className='nav-item'>
              <button
                className='btn btn-walmartLightBlue text-nowrap'
                style={{ marginLeft: '15px' }}
                onClick={async () => navigate('/home')}
              >
                See Demo
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default HomeNav
