import React from 'react'

import { HomeNav, Nav, NotFoundNav, TerminalNav, UserNav } from '../Nav'

import 'modern-normalize/modern-normalize.css'

import '../../scss/styles.scss'
import Footer from '../Footer'

import Helmet from 'react-helmet'

import Seo from '../Seo'

import { Redirect } from '@reach/router'

import { AppUser } from '../Auth'

const favicon = require('../../assets/images/bluefilled.png')

type layoutInputs = {
  children: any
  isUserNav: boolean | undefined
}

export function Layout({ children, isUserNav }: layoutInputs) {
  return (
    <div className='Site'>
      <Helmet>
        <link rel='icon' href={favicon} />
      </Helmet>
      {isUserNav === true ? <UserNav /> : <Nav />}
      <div className='Site-content'>{children}</div>
    </div>
  )
}

export function TerminalLayout({ children }: layoutInputs) {
  return (
    <div className='terminalSite'>
      <Helmet>
        <link rel='icon' href={favicon} />
      </Helmet>
      <TerminalNav />
      <div className='Site-content'>{children}</div>
    </div>
  )
}

export function NotFoundLayout({ children }: any) {
  return (
    <div className='Site'>
      <Helmet>
        <link rel='icon' href={favicon} />
      </Helmet>
      <NotFoundNav />
      <div className='Site-content'>{children}</div>
    </div>
  )
}

export function Landing({ children, isUserNav }: layoutInputs) {
  return (
    <div className='Site'>
      <Helmet>
        <link rel='icon' href={favicon} />
      </Helmet>
      {isUserNav === true ? <UserNav /> : <Nav />}
      <div className='Site-content'>{children}</div>
    </div>
  )
}

export function Home({ children }: any) {
  if (typeof window !== 'undefined') {
    window.onscroll = function() {
      const top = window.scrollY

      if (document.getElementById('topNav') !== null) {
        if (top > 50) {
          document.getElementById('topNav')!.className = 'fixed'
        } else {
          document.getElementById('topNav')!.className = 'top'
        }
      }
    }
  }

  return (
    <div className='siteHome'>
      <Seo />
      <Helmet>
        <link rel='icon' href={favicon} />
      </Helmet>
      <header className='top' id='topNav'>
        <HomeNav />
      </header>
      <div className='Site-content'>{children}</div>
    </div>
  )
}

export function AppContent({ children }: any) {
  return (
    <div className='app-content-100'>
      <div className='container' style={{ marginTop: 40 }}>
        {children}
      </div>
    </div>
  )
}
