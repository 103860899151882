import { navigate } from '@reach/router'
import { Auth } from 'aws-amplify'
import { Link } from 'gatsby'
import React from 'react'

import { AppUser } from '../Auth'
import { getCurrentUser } from '../Auth/AppUser'

const $ = require('jquery')

const logo = require('../../assets/images/white.png')

function TerminalNav() {
  const { logout } = AppUser
  function logOut(e: React.MouseEvent<HTMLParagraphElement>) {
    e.preventDefault()

    Auth.signOut()
      .then(logout(async () => navigate('/signin')))
      // TODO(639): handle errors so we are aware, not the user first
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch((err) => {})
  }

  return (
    <nav className='navbar navbar-user navbar-expand-lg navbar-dark navbar-toggleable-md infront'>
      <Link className='text-center' to='/'>
        <h1 className='navbar-brand mb-0 text-default'>
          <img src={logo} height='40' />
          Stratos
        </h1>
      </Link>

      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNav'
        aria-controls='navbarNav'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon'></span>
      </button>
      <div
        className='collapse navbar-collapse right navbar-nav'
        data-toggle='collapse'
        data-target='.navbar-collapse'
        id='navbarNav'
      >
        <ul className='navbar-nav bd-navbar-nav ml-auto'>

          <li className='nav-item dropdown'>
            <a
              className='text-default nav-link'
              role='button'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <i className='bi bi-person-lines-fill'></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default TerminalNav
