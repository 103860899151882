const isBrowser = typeof window !== 'undefined'

export const setUser = (
  user: any,
) => (window.localStorage.gatsbyUser = JSON.stringify(user))

const getUser = () => {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (window.localStorage.gatsbyUser) {
    // TODO(484): remove unsafe JSON.parse
    const user = JSON.parse(window.localStorage.gatsbyUser)
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    return user ? user : {}
  }
  return {}
}

export const isLoggedIn = () => {
  if (!isBrowser) { return false }

  const user = getUser()
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  if (user) { return !!user.username }
  return false
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = (callback: any): any => {
  if (!isBrowser) { return }
  setUser({})
  callback()
}
