import { navigate } from '@reach/router'
import { Auth } from 'aws-amplify'
import { Link } from 'gatsby'
import React from 'react'

import { AppUser } from '../Auth'
import { getCurrentUser } from '../Auth/AppUser'

const $ = require('jquery')

const logo = require('../../assets/images/blue.png')

function UserNav() {
  $(function() {
    $('.navbar').on('show.bs.collapse', function() {
      $('.navbar-default').css('background-color', '#e9ecef')
    })

    $('.navbar').on('hide.bs.collapse', function() {
      $('.navbar-default').css('background-color', 'transparent')
    })
  })

  const { logout } = AppUser
  function logOut(e: React.MouseEvent<HTMLParagraphElement>) {
    e.preventDefault()

    Auth.signOut()
      .then(logout(async () => navigate('/signin')))
      // TODO(639): handle errors so we are aware, not the user first
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      .catch((err) => {})
  }

  return (
    <nav className='navbar navbar-default navbar-expand-lg navbar-light navbar-toggleable-md infront'>
      <Link className='text-center' to='/'>
        <h1 className='navbar-brand mb-0 text-primary'>
          <img src={logo} height='70' />
          Stratos
        </h1>
      </Link>

      <button
        className='navbar-toggler'
        type='button'
        data-toggle='collapse'
        data-target='#navbarNav'
        aria-controls='navbarNav'
        aria-expanded='false'
        aria-label='Toggle navigation'
      >
        <span className='navbar-toggler-icon'></span>
      </button>
      <div
        className='collapse navbar-collapse right navbar-nav'
        data-toggle='collapse'
        data-target='.navbar-collapse'
        id='navbarNav'
      >
        <ul className='navbar-nav bd-navbar-nav ml-auto'>
          <li className='nav-item'>
            <Link to='/home'>
              <p
                style={{ margin: 0 }}
                className='nav-link text-primary'
              >
                Trade
              </p>
            </Link>
          </li>

          <li className='nav-item dropdown'>
            <a
              className='text-primary nav-link'
              role='button'
              id='dropdownMenuButton'
              data-toggle='dropdown'
              aria-haspopup='true'
              aria-expanded='false'
            >
              <i className='bi bi-person-lines-fill'></i>
            </a>
            <div
              className='dropdown-menu dropdown-menu-right'
              aria-labelledby='dropdownMenuButton'
            >
              <h6 className='dropdown-header'>{getCurrentUser().email}</h6>

              <div className='dropdown-divider'></div>
                Settings
                Pricing
              <div className='dropdown-divider'></div>
              <div className='dropdown-item'>
                <i className='bi bi-box-arrow-right mr-2'></i>
                Log Out
              </div>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default UserNav
