import { navigate } from '@reach/router'
import { Link } from 'gatsby'
import React from 'react'

const logo = require('../../assets/images/blue.png')

function Nav() {
  return (
    <nav className='navbar navbar-expand resized'>
      <Link className='text-center' to='/'>
        <h1 className='navbar-brand mb-0 text-stratosBlue'>
          <img src={logo} style={{ height: 70 }} />
          Stratos
        </h1>
      </Link>
      <div className='navbar-nav-scroll d-flex flex-grow-1' />
      <div className='navbar-nav-scroll'>
        <ul className='navbar-nav bd-navbar-nav flex-row'>
          <li className='nav-item'>
            <button
              className='btn btn-outline-stratosBlue'
              onClick={async () => navigate('/signin')}
            >
              Sign In
            </button>
          </li>
          <li className='nav-item'>
            <button
              className='btn btn-walmartLightBlue'
              style={{ marginLeft: '5px' }}
              onClick={async () => navigate('/signup')}
            >
              Get Started
            </button>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default Nav
