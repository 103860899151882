import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { Helmet } from 'react-helmet'

type SeoStructure = {
  description?: string
  keywords?: string[]
  title?: string
  image?: string
  url?: string
  author?: string
}

const detailsQuery = graphql `
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
        image
      }
    }
  }
`

/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
const Seo = ({ description, keywords, title, image, url, author }: SeoStructure) => {
  return (
    <StaticQuery
      query={detailsQuery}
      render={(data) => {
        const metaDescription = description || data.site.siteMetadata.description
        const metaTitle = title || data.site.siteMetadata.title
        const metaAuthor = author || data.site.siteMetadata.author
        const metaUrl = url || data.site.siteMetadata.url
        const metaImage = image || `${metaUrl}${data.site.siteMetadata.image}`
        const metaKeywords = keywords || [
          'crypto trading bots, crypto bot, systematic and autonomous trading, copy trading',
        ]
        return (
          <Helmet
            title={title}
            meta={[
              {
                name: 'description',
                content: metaDescription,
              },
              {
                property: 'og:title',
                content: metaTitle,
              },
              {
                property: 'og:description',
                content: metaDescription,
              },
              {
                property: 'og:type',
                content: 'website',
              },
              {
                property: 'og:image',
                content: metaImage,
              },
              {
                property: 'og:url',
                content: metaUrl,
              },
              {
                name: 'twitter:card',
                content: 'summary_large_image',
              },
              {
                name: 'twitter:creator',
                content: metaAuthor,
              },
              {
                name: 'twitter:title',
                content: metaTitle,
              },
              {
                name: 'twitter:description',
                content: metaDescription,
              },
              {
                name: 'twitter:image',
                content: metaImage,
              },
            ].concat(
              metaKeywords.length > 0
                ? {
                  name: 'keywords',
                  content: metaKeywords.join(', '),
                }
                : [],
            )}
          />
        )
      }}
    />
  )
}
/* eslint-enable @typescript-eslint/strict-boolean-expressions */
/* eslint-enable @typescript-eslint/prefer-nullish-coalescing */

export default Seo
